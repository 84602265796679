import { runInAction, makeAutoObservable } from 'mobx';
import { Anviz, Zkteco, ControlId, Equipamento, Hikvision, IntelbrasFacial } from '../interfaces/Equipamento.interface';
import EquipamentoAPI from '../api/Equipamento';
import { ControleRelatorio } from '../interfaces/Dispositivo.interface';
import DispositivoAPI from '../api/DispositivoAPI';

class DispositivosStore {

    controlIdSelecionado: ControlId | null = null;
    controlIds: ControlId[] = [];
    intelbrasSelecionado: IntelbrasFacial | null = null;
    intelbras: IntelbrasFacial[] = [];
    hikvisionSelecionado: Hikvision | null = null;
    hikvision: Hikvision[] = [];
    anvizSelecionado: Anviz | null = null;
    anviz: Anviz[] = [];
    zktecoSelecionado: Zkteco | null = null;
    zkteco: Zkteco[] = [];
    loading: boolean = false;
    controles: ControleRelatorio[] = [];
    equipamentos: Equipamento[] = [];
    equipamentosBiometria: Equipamento[] = [];
    selecionado: Equipamento | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    async getControlId() {
        this.loading = true;
        try {
            let condominioAtual = localStorage.getItem('condominio');
            if (condominioAtual) {
                const { data } = await EquipamentoAPI.listarControlIdPorCondominio(condominioAtual);
                runInAction(() => {
                    if (data) this.controlIds = data;
                    else this.controlIds = [];
                    this.loading = false;
                });
            }
        } catch (error) {
            console.error("Erro ao buscar dados da ControlId:", error);
        } finally {
            this.loading = false;
        }
    }

    async getHikvision() {
        this.loading = true;
        try {
            let condominioAtual = localStorage.getItem('condominio');
            if (condominioAtual) {
                const { data } = await EquipamentoAPI.listarHikvisionPorCondominio(condominioAtual);
                runInAction(() => {
                    if (data) this.hikvision = data;
                    else this.hikvision = data;
                    this.loading = false;
                });
            }
        } catch (error) {
            console.error("Erro ao buscar dados da Hikvision:", error);
        } finally {
            this.loading = false;
        }
    }

    async getAnviz() {
        this.loading = true;
        try {
            let condominioAtual = localStorage.getItem('condominio');
            if (condominioAtual) {
                const data_Anviz = await EquipamentoAPI.listarAnvizPorCondominio(condominioAtual);
                runInAction(() => {
                    this.anviz = data_Anviz.data;
                    this.loading = false;
                });
            }
        } catch (error) {
            console.error("Erro ao buscar dados da Anviz:", error);
        } finally {
            this.loading = false;
        }
    }

    async getIntelbras() {
        this.loading = true;
        try {
            let condominioAtual = localStorage.getItem('condominio');
            if (condominioAtual) {
                const { data } = await EquipamentoAPI.listarIntelbrasPorCondominio(condominioAtual);
                runInAction(() => {
                    this.intelbras = data;
                    this.loading = false;
                });
            }
        } catch (error) {
            console.error("Erro ao buscar dados da Intelbras:", error);
        } finally {
            this.loading = false
        }
    }

    async getZkteco() {
        this.loading = true;
        try {
            let condominioAtual = localStorage.getItem('condominio');
            if (condominioAtual) {
                const { data } = await EquipamentoAPI.listarZktecoPorCondominio(condominioAtual);
                runInAction(() => {
                    this.zkteco = data;
                    this.loading = false;
                });
            }
        } catch (error) {
            console.error("Erro ao buscar dados da Zkteco:", error);
        } finally {
            this.loading = false;
        }
    }

    async listarEquipamentos() {
        this.getControlId();
        this.getHikvision();
        this.getIntelbras();
        this.getZkteco();
        this.getAnviz();
    }

    async listarEquipamentosBiometriaPorCondominio(condominioId: string) {
        this.loading = true;
        try {
            const { data } = await EquipamentoAPI.listarEquipamentoBiometria(condominioId);
            runInAction(() => {
                if (data) this.equipamentosBiometria = data;
                else this.equipamentosBiometria = [];
                this.loading = false;
            });
        } catch (error) {
            console.error("Erro ao buscar dados de equipamentos:", error);
        } finally {
            this.loading = false;
        }
    }

    async listarEquipamentosBiometriaPorMorador(moradorId: string) {
        this.loading = true;
        try {
            const { data } = await EquipamentoAPI.listarEquipamentoPorMorador(moradorId);
            runInAction(() => {
                if (data) this.equipamentosBiometria = data;
                else this.equipamentosBiometria = [];
                this.loading = false;
            });
        } catch (error) {
            console.error("Erro ao buscar dados de equipamentos:", error);
        } finally {
            this.loading = false;
        }
    }

    async listarEquipamentosPorCondominio(condominioId: string) {
        this.loading = true;
        try {
            const { data } = await DispositivoAPI.listarEquipamentoPorCondominioId(condominioId);
            runInAction(() => {
                if (data) this.equipamentos = data;
                else this.equipamentos = [];
                this.loading = false;
            });
        } catch (error) {
            console.error("Erro ao buscar dados de equipamentos:", error);
        } finally {
            this.loading = false;
        }
    }

    async definirControlIdSelecionado(controlIdId: string) {
        let selecionado = this.controlIds.find((con) => con.id === controlIdId);
        if (selecionado) {
            this.controlIdSelecionado = selecionado;
        }
    }

    definirHikvisionSelecionado(hikvisionId: string) {
        let selecionado = this.hikvision.find((hik) => hik.id === hikvisionId);
        if (selecionado) {
            this.hikvisionSelecionado = selecionado;
        }
    }

    definirIntelbrasSelecionado(intelbrasId: string) {
        let selecionado = this.intelbras.find((int) => int.id === intelbrasId);
        if (selecionado) {
            this.intelbrasSelecionado = selecionado;
        }
    }

    async definirAnvizSelecionado(anviz: string) {
        let selecionado = this.anviz.find((anv) => anv.id === anviz);
        if (selecionado) {
            this.anvizSelecionado = selecionado;
        }
    }

    async definirZktecoSelecionado(zkteco: string) {
        let selecionado = this.zkteco.find((zkt) => zkt.id === zkteco);
        if (selecionado) {
            this.zktecoSelecionado = selecionado;
        }
    }

    async definirSelecionado(equipamentoId: string | null) {
        let selecionado = this.equipamentosBiometria.find(eqp => eqp.equipamentoId === equipamentoId);
        if (selecionado) {
            this.selecionado = selecionado;
        } else {
            this.selecionado = null;
        }
    }

}

export default DispositivosStore;