import { useContext, useState } from "react";
import { toast } from "react-toastify";
import EquipamentoAPI from "../api/Equipamento";
import { StoreContext } from "../stores";
import { Anviz, ControlId, Hikvision, IntelbrasFacial, Zkteco } from "../interfaces/Equipamento.interface";


const useEquipamento = () => {

    // CONTROLID
    const { equipamentoStore } = useContext(StoreContext);
    const [salvandoControlId, setSalvandoControlId] = useState(false);
    const [atualizandoControlId, setAtualizandoControlId] = useState(false);
    const [deletandoControlId, setDeletandoControlId] = useState(false);

    // HIKVISION
    const [salvandoHikvision, setSalvandoHikvision] = useState(false);
    const [atualizandoHikvision, setAtualizandoHikvision] = useState(false);
    const [deletandoHikvision, setDeletandoHikvision] = useState(false);

    // ANVIZ
    const [salvandoAnviz, setSalvandoAnviz] = useState(false);
    const [atualizandoAnviz, setAtualizandoAnviz] = useState(false);
    const [deletandoAnviz, setDeletandoAnviz] = useState(false);

    // ZKTECO

    const [salvandoZkteco, setSalvandoZkteco] = useState(false);
    const [atualizandoZkteco, setAtualizandoZkteco] = useState(false);
    const [deletandoZkteco, setDeletandoZkteco] = useState(false);

    // INTELBRAS
    const [salvandoIntelbras, setSalvandoIntelbras] = useState(false);
    const [atualizandoIntelbras, setAtualizandoIntelbras] = useState(false);
    const [deletandoIntelbras, setDeletandoIntelbras] = useState(false);

    // CONTROLID
    const cadastrarControlId = async (controlId: ControlId, hide: () => void) => {
        setSalvandoControlId(true);
        try {
            const { data, status } = await EquipamentoAPI.salvarControlId(controlId);
            if (status === 200) {
                equipamentoStore.controlIds.push(data);
                toast.success('Control ID cadastrado com sucesso');
                equipamentoStore.listarEquipamentos();
                hide();
                setSalvandoControlId(false);
            } else {
                toast.error('Erro ao cadastrar control ID');
                setSalvandoControlId(false);
            }
        } catch (e) {
            console.error("Falha ao Cadastrar", e);
        } finally {
            setSalvandoControlId(false);
        }
    }
    const atualizarControlId = async (controlId: ControlId, hide: () => void) => {
        setAtualizandoControlId(true);
        try {
            const { status } = await EquipamentoAPI.atualizarControlId(controlId);
            if (status === 200) {
                equipamentoStore.controlIds = equipamentoStore.controlIds.map(con => con.id === controlId.id ? controlId : con);
                toast.success('Control ID atualizado com sucesso');
                hide();
                setAtualizandoControlId(false);
            } else {
                toast.error('Erro ao atualizar control ID');
                setAtualizandoControlId(false);
            }
        } catch (e) {
            console.error("Falha ao atualizar",e);
        } finally {
            setAtualizandoControlId(false);
        }
    }
    const deletarControlId = async (controlId: ControlId) => {
        setDeletandoControlId(true);
        try {
            const { status } = await EquipamentoAPI.deletarControlId(controlId.id);
            if (status === 200) {
                equipamentoStore.controlIds = equipamentoStore.controlIds.filter(con => con.id !== controlId.id);
                toast.success('Control ID deletado com sucesso');
                setDeletandoControlId(false);
            } else {
                toast.error('Erro ao deletar control ID');
                setDeletandoControlId(false);
            }
        } catch (e) {
            console.error("Falha ao Deletar", e);
        } finally {
            setDeletandoControlId(false);
        }
    }

    // HIKVISION
    const cadastrarHikvision = async (hikvision: Hikvision, hide: () => void) => {
        setSalvandoHikvision(true);
        try {
            const { data, status } = await EquipamentoAPI.salvarHikvision(hikvision);
            if (status === 200) {
                equipamentoStore.hikvision.push(data);
                toast.success('Hikvision cadastrado com sucesso');
                equipamentoStore.listarEquipamentos();
                hide();
                setSalvandoHikvision(false);
            } else {
                toast.error('Erro ao cadastrar hikvision');
                setSalvandoHikvision(false);
            }
        } catch (e) {
            console.error("Falha ao Cadastrar", e);
        } finally {
            setSalvandoHikvision(false);
        }
    }
    const atualizarHikvision = async (hikvision: Hikvision, hide: () => void) => {
        setAtualizandoHikvision(true);
        try {
            const { status } = await EquipamentoAPI.atualizarHikvision(hikvision);
            if (status === 200) {
                equipamentoStore.hikvision = equipamentoStore.hikvision.map(hik => hik.id === hikvision.id ? hikvision : hik);
                toast.success('Hikvision atualizado com sucesso');
                hide();
                setAtualizandoHikvision(false);
            } else {
                toast.error('Erro ao atualizar hikvision');
                setAtualizandoHikvision(false);
            }
        } catch (e) {
            console.error("Falha ao Atualizar", e);
        } finally {
            setAtualizandoHikvision(false);
        }
    }
    const deletarHikvision = async (hikvision: Hikvision) => {
        setDeletandoHikvision(true);
        try {
            const { status } = await EquipamentoAPI.deletarHikvision(hikvision.id);
            if (status === 200) {
                equipamentoStore.hikvision = equipamentoStore.hikvision.filter(hik => hik.id !== hikvision.id);
                toast.success('Hikvision deletado com sucesso');
                setDeletandoHikvision(false);
            } else {
                toast.error('Erro ao deletar hikvision');
                setDeletandoHikvision(false);
            }
        } catch (e) {
            console.error("Falha ao Deletar", e);
        } finally {
            setDeletandoHikvision(false);
        }
    }

    // ANVIZ
    const cadastrarAnviz = async (anviz: Anviz, hide: () => void) => {
        setSalvandoAnviz(true);
        try {
            const { data, status } = await EquipamentoAPI.salvarAnviz(anviz);
            if (status === 200) {
                equipamentoStore.anviz.push(data);
                toast.success('Anviz cadastrado com sucesso');
                equipamentoStore.listarEquipamentos();
                hide();
                setSalvandoAnviz(false);
            } else {
                toast.error('Erro ao cadastrar anviz');
                setSalvandoAnviz(false);
            }
        } catch (e) {
            console.error("Falha ao Cadastrar", e);
        } finally {
            setSalvandoAnviz(false);
        }
    }
    const atualizarAnviz = async (anviz: Anviz, hide: () => void) => {
        setAtualizandoAnviz(true);
        try {
            const { status } = await EquipamentoAPI.atualizarAnviz(anviz);
            if (status === 200) {
                equipamentoStore.anviz = equipamentoStore.anviz.map(a => a.id === a.id ? anviz : a);
                equipamentoStore.listarEquipamentos();
                toast.success('Anviz atualizado com sucesso');
                hide();
                setAtualizandoAnviz(false);
            } else {
                toast.error('Erro ao atualizar anviz');
                setAtualizandoAnviz(false);
            }
        } catch (e) {
            console.error("Falha ao Atualizar", e);
        } finally {
            setAtualizandoAnviz(false);
        }
    }
    const deletarAnviz = async (anviz: Anviz) => {
        setDeletandoAnviz(true);
        try {
            const { data } = await EquipamentoAPI.deletarAnviz(anviz.id);
            equipamentoStore.anviz = equipamentoStore.anviz.filter(anv => anv.id !== anviz.id);
            toast.success('Anviz deletado com sucesso');
            setDeletandoAnviz(false);
        } catch (e) {
            toast.error('Erro ao deletar anviz');
            console.error("Falha ao Deletar", e);
        } finally {
            setDeletandoAnviz(false);
        }
    }

    // INTELBRAS
    const cadastrarIntelbras = async (intelbras: IntelbrasFacial, hide: () => void) => {
        setSalvandoIntelbras(true);
        try {
            const { data, status } = await EquipamentoAPI.salvarIntelbras(intelbras);
            if (status === 200) {
                toast.success('Intelbras cadastrado com sucesso');
                equipamentoStore.listarEquipamentos();
                hide();
                setSalvandoIntelbras(false);
            } else {
                toast.error('Erro ao cadastrar intelbras');
                setSalvandoIntelbras(false);
            }
        } catch (e) {
            console.error("Falha ao Cadastrar", e);
        } finally {
            setSalvandoIntelbras(false);
        }
    }
    const atualizarIntelbras = async (intelbras: IntelbrasFacial, hide: () => void) => {
        setAtualizandoIntelbras(true);
        try {
            const { status } = await EquipamentoAPI.atualizarIntelbras(intelbras);
            if (status === 200) {
                equipamentoStore.intelbras = equipamentoStore.intelbras.map(int => int.id === intelbras.id ? intelbras : int);
                toast.success('Intelbras atualizado com sucesso');
                hide();
                setAtualizandoIntelbras(false);
            } else {
                toast.error('Erro ao atualizar intelbras');
                setAtualizandoIntelbras(false);
            }
        } catch (e) {
            console.error("Falha ao Atualizar", e);
        } finally {
            setAtualizandoIntelbras(false);
        }
    }
    const deletarIntelbras = async (intelbras: IntelbrasFacial) => {
        setDeletandoIntelbras(true);
        try {
            const { data } = await EquipamentoAPI.deletarIntelbras(intelbras);
            equipamentoStore.intelbras = equipamentoStore.intelbras.filter(int => int.id !== intelbras.id);
            toast.success('Intelbras deletado com sucesso');
            setDeletandoIntelbras(false);
        } catch (e) {
            toast.error('Erro ao deletar intelbras');
            console.error("Falha ao Deletar", e);
        } finally {
            setDeletandoIntelbras(false);
        }
    }

    //Zkteco

    const cadastrarZkteco = async (zkteco: Zkteco, hide: () => void) => {
        setSalvandoZkteco(true);
        try {
            const { data, status } = await EquipamentoAPI.salvarZkteco(zkteco);
            if (status === 200) {
                equipamentoStore.zkteco.push(data);
                toast.success('Zkteco cadastrado com sucesso');
                equipamentoStore.listarEquipamentos();
                hide();
                setSalvandoZkteco(false);
            } else {
                toast.error('Erro ao cadastrar Zkteco');
                setSalvandoZkteco(false);
            }
        } catch (e) {
            console.error("Falha ao Cadastrar", e);
        } finally {
            setSalvandoZkteco(false);
        }
    }
    const atualizarZkteco = async (zkteco: Zkteco, hide: () => void) => {
        setAtualizandoZkteco(true);
        try {
            const { status } = await EquipamentoAPI.atualizarZkteco(zkteco);
            if (status === 200) {
                equipamentoStore.listarEquipamentos();
                toast.success('Zkteco atualizado com sucesso');
                hide();
                setAtualizandoZkteco(false);
            } else {
                toast.error('Erro ao atualizar Zkteco');
                setAtualizandoZkteco(false);
            }
        } catch (e) {
            console.error("Falha ao Atualizar", e);
        } finally {
            setAtualizandoZkteco(false);
        }
    }
    const deletarZkteco = async (zkteco: Zkteco) => {
        setDeletandoZkteco(true);
        try {
            await EquipamentoAPI.deletarZkteco(zkteco.id);
            equipamentoStore.zkteco = equipamentoStore.zkteco.filter(int => int.id !== zkteco.id);
            toast.success('Zkteco deletado com sucesso');
            setDeletandoZkteco(false);
        } catch (e) {
            toast.error('Erro ao deletar Zkteco');
            console.error("Falha ao Deletar", e);
        } finally {
            setDeletandoZkteco(false);
        }
    }

    return {

        //CONTROL ID
        cadastrarControlId,
        atualizarControlId,
        deletarControlId,
        salvandoControlId,
        atualizandoControlId,
        deletandoControlId,

        //HIKIVISION
        cadastrarHikvision,
        atualizarHikvision,
        deletarHikvision,
        salvandoHikvision,
        atualizandoHikvision,
        deletandoHikvision,

        //INTELBRAS
        cadastrarIntelbras,
        atualizarIntelbras,
        deletarIntelbras,
        salvandoIntelbras,
        atualizandoIntelbras,
        deletandoIntelbras,

        //ANVIZ
        cadastrarAnviz,
        atualizarAnviz,
        deletarAnviz,
        salvandoAnviz,
        atualizandoAnviz,
        deletandoAnviz,

        //ZKTECO
        cadastrarZkteco,
        atualizarZkteco,
        deletarZkteco,
        salvandoZkteco,
        atualizandoZkteco,
        deletandoZkteco,
    }

}


export default useEquipamento;