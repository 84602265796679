import { createContext } from "react";
import BlocoStore from "./BlocoStore";
import CondominioStore from "./CondominioStore";
import UnidadeStore from "./UnidadeStore";
import MoradorStore from "./MoradorStore";
import AuthStore from "./AuthStore";
import GuaritaStore from "./GuaritaStore";
import AcionamentoStore from "./AcionamentoStore";
import ReleStore from "./ReleStore";
import ComandoStore from "./ComandoStore";
import SipStore from "./SipStore";
import RamalStore from "./RamalStore";
import LayoutStore from "./LayoutStore";
import VeiculoStore from "./VeiculoStore";
import VisitanteStore from "./VisitanteStore";
import AcionamentoPerfilStore from "./AcionamentoPerfilStore";
import EquipamentoStore from "./EquipamentoStore";
import CameraStore from "./CameraStore";
import UsuarioStore from "./UsuarioStore";
import EventoStore from "./EventoStore";
import ThemeStore from "./ThemeStore";
import DispositivosStore from "./DispositivoStore";
import RedeStore from "./RedeStore";
import PermissoesStore from "./PermissoesStore";
import NotaStore from "./NotaStore";
import LogStore from "./LogStore";
import SiamStore from "./SiamStore";
import ChatStore from "./ChatStore";
import BravaStore from "./BravasStore";
import AutomacaoAcionamentoIdentificacaoStore from "./AutomacaoAcionamentoIdentificacaoStore";
import AutomacaoAcionamentoStore from "./AutomacaoAcionamentoStore";
import FuncionarioStore from "./FuncionarioStore";
import Mip1000Store from "./Mip1000Store";
import RelatorioStore from "./RelatorioStore";
import OctosStore from "./OctosStore";
import ReportStore from "./ReportStore";
import AvisoStore from "./AvisoStore";
import ZktecoStore from "./ZktecoStore";

export const StoreContext = createContext({
    condominioStore: new CondominioStore(),
    blocoStore: new BlocoStore(),
    unidadeStore: new UnidadeStore(),
    moradorStore: new MoradorStore(),
    authStore: new AuthStore(),
    guaritaStore: new GuaritaStore(),
    acionamentoStore: new AcionamentoStore(),
    releStore: new ReleStore(),
    comandoStore: new ComandoStore(),
    sipStore: new SipStore(),
    ramalStore: new RamalStore(),
    layoutStore: new LayoutStore(),
    veiculoStore: new VeiculoStore(),
    visitanteStore: new VisitanteStore(),
    acionamentoPerfilStore: new AcionamentoPerfilStore(),
    equipamentoStore: new EquipamentoStore(),
    cameraStore: new CameraStore(),
    usuarioStore: new UsuarioStore(),
    eventoStore: new EventoStore(),
    themeStore: new ThemeStore(),
    dispositivosStore: new DispositivosStore(),
    redeStore: new RedeStore(),
    permissoesStore: new PermissoesStore(),
    notaStore: new NotaStore(),
    logStore: new LogStore(),
    siamStore: new SiamStore(),
    chatStore: new ChatStore(),
    automacaoAcionamentoIdentificacaoStore: new AutomacaoAcionamentoIdentificacaoStore(),
    automacaoAcionamentoStore: new AutomacaoAcionamentoStore(),
    bravasStore: new BravaStore(),
    mip1000Store: new Mip1000Store(),
    zktecoStore: new ZktecoStore(),
    relatorioStore: new RelatorioStore(),
    octosStore: new OctosStore(),
    reportStore: new ReportStore(),
    funcionarioStore: new FuncionarioStore(),
    avisoStore: new AvisoStore(),
});