import React, { FormEvent, useContext, useEffect, useState } from 'react';
import { Container, ContainerForm, ContainerInfo, ContainerModal, TransitionDiv } from './styles/Login.style';
import { Backdrop, CircularProgress, IconButton, LinearProgress, Snackbar } from '@mui/material';
import { Modal } from 'react-bootstrap';
import { GrUserAdmin } from 'react-icons/gr';
import { MdConnectWithoutContact } from 'react-icons/md';
import operacao from '../../assets/img/operacao.png';
import ti from '../../assets/img/ti.png';
import relacionamento from '../../assets/img/relacionamento.png';
import { cpfMask } from '../../components/utils/mask';
import { StoreContext } from '../../stores';
import { Auth, AuthRequest } from '../../interfaces/Auth.interface';
import { BsX } from 'react-icons/bs';
import { useAuthentication } from '../../hooks/Auth.hook';
import { useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Button } from 'rsuite';
import ModalEsqueceuSenha from './ModalEsqueceuSenha';

const acesso = [
    {
        icone: <MdConnectWithoutContact/>,
        label: 'Operação',
        img: operacao
    },
    {
        icone: <MdConnectWithoutContact/>,
        label: 'TI',
        img: ti
    },
    {
        icone: <MdConnectWithoutContact/>,
        label: 'Relacionamento',
        img: relacionamento
    },
]

const Login = () => {

    const { themeStore } = useContext(StoreContext);
    const { Authentication, carregando, erro, status } = useAuthentication();
    const [form, setForm] = useState<AuthRequest>();
    const [state, setState] = useState(true);
    const [open, setOpen] = useState(false);
    const history = useNavigate();

    useEffect(() =>
    {
        localStorage.clear();
        themeStore.theme = 'light';
    },[]);
    
    const login = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        Authentication(form as Auth, state)
    }

    const toggleOpen = () => setOpen(!open);

    const action = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
          >
            <BsX fontSize="small" />
          </IconButton>
        </React.Fragment>
      );

    const _loading = () => {
        if(carregando) {
            return(
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                    >
                <LinearProgress color='info' style={{height: 2, width: '100%', position: 'absolute', bottom: 0}} />
                    <CircularProgress color="inherit" />
                </Backdrop>
            )
        }
    }

    const _erro = () => {
        if(erro) {
            return(
                <Snackbar
                    open={erro}
                    autoHideDuration={2000}
                    message="Usuário ou senha incorreto!"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
            )
        }
    }

    const renderForm = () => {
        return(
            <div className='container animation'>
                <p className='title'>Seja bem vindo</p>
                <p className='subtitle'>
                    Olá, novamente! 
                </p>
                <p className='info'>Informe seu cpf e a sua senha para ter acesso ao sistema administrativo</p>

                <form onSubmit={(e) => login(e)}>
                    <input className='form-control shadow-none input' placeholder='Digite seu CPF' value={form?.login || ''} onChange={ (e) => setForm({ ...form, login: cpfMask(e.target.value.toString().replace(/\.|-/gm,'')) }) } />
                    <input type={'password'} className='form-control shadow-none input' placeholder='********' value={form?.senha || ''} onChange={ (e) => setForm({ ...form, senha: e.target.value }) } />
                    <button className= {'btn-enter'} disabled={carregando || !form }>{carregando ? 'Carregando...' : 'Entrar'}</button>
                </form>

                <div className='d-flex justify-content-center mt-2' role={'button'}>
                    <a>Esqueci a senha</a>
                </div>
            </div>
        )
    }

    // const renderAcessControl = () => {
    //     return(
    //         <Modal
    //             show={status}
    //             backdrop="static"
    //             keyboard={false}
    //             size='xl'
    //             centered
    //         >
    //             <Modal.Header>
    //             <Modal.Title>Controle de acesso do usuário</Modal.Title>
    //             </Modal.Header>
    //             <Modal.Body>
    //                 <ContainerModal>
    //                     <p className='title'>Abaixo está informando os acessos que você tem direito a navegar</p>

    //                     <div className='d-flex justify-content-center'>
    //                         {
    //                             acesso.map((acesso, i) => (
    //                                 <button onClick={ () => redirect() } key={i} className='button-acess'>
    //                                     <div className='d-flex justify-content-center'><img src={acesso.img} className='img' /></div>
    //                                     <p className='title-img'>{acesso.label}</p>
    //                                 </button>
    //                             ))
    //                         }
    //                     </div>
    //                 </ContainerModal>
    //             </Modal.Body>
    //         </Modal>
    //     )
    // }

    if(status) history('/condominios');

        
    // const baseURL1 = process.env.REACT_APP_BASE_MS_AUTHENTICATION?.replace(/'/g, '');
    // const baseURL2 = process.env.REACT_APP_BASE_API;

    // 
    // 

    return(
        <Container>
            {_loading()}
            {_erro()}
            {/* {renderAcessControl()} */}
            <ContainerInfo>
                <div className='container'>
                    <p className='title'>Outkey - Soluções Tecnológicas</p>
                    <p className='subtitle'>
                        Painel administrativo para os profissionais da empresa!
                    </p>
                    <p className='copyright'>Outkey Soluções Tecnológicas - © Todos os direitos reservados.</p>
                </div>
            </ContainerInfo>
            <ContainerForm>

            {renderForm()}

            {/* <TransitionDiv className=''>
                <SwitchTransition mode={'out-in'}>
                    <CSSTransition
                        key={state ? "Hello, world!" : "Goodbye, world!"}
                        addEndListener={(node, done) => {
                        node.addEventListener("transitionend", done, false);
                        }}
                        classNames="fade"
                    >
                        <div>
                        {state ? (
                            renderForm()
                        ) : (
                            renderFormPorEmail()
                        )}
                        </div>
                    </CSSTransition>
                </SwitchTransition>
            </TransitionDiv> */}
            {/* <video src='http://192.168.10.41:554/mpeg4/ch01/main/av_stream'/> */}

            </ContainerForm>
            <ModalEsqueceuSenha show={open} hide={toggleOpen} />
        </Container>
    );
}

export default Login;