import { Backdrop } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineCheck, AiOutlineReload } from 'react-icons/ai'
import { BiErrorCircle, BiFace, BiFingerprint } from 'react-icons/bi'
import { BsTags } from 'react-icons/bs'
import { FaPlane } from 'react-icons/fa'
import { FiArrowRight } from 'react-icons/fi'
import { HiOutlineDatabase, HiOutlineDesktopComputer } from 'react-icons/hi'
import { MdFingerprint, MdOutlinePassword } from 'react-icons/md'
import { RiRemoteControlLine, RiRouterFill } from 'react-icons/ri'
import { Button, Drawer, Loader, Nav, SelectPicker, Timeline } from 'rsuite'
import { useDispositivo } from '../../hooks/Dispositivo.hook'
import { useUsuario } from '../../hooks/usuario.hook'
import { Usuario, UsuarioRequest } from '../../interfaces/Usuario.interface'
import { StoreContext } from '../../stores'
import ModalConfirmacao from '../utils/ModalConfirmacao'
import NotFound from '../utils/NotFound'
import { Container, ContainerComponent } from './styles/Sincronizacao.style'
import { TbDatabaseExport, TbFileDatabase } from 'react-icons/tb'

const SincronizacaoCard = () => {

  const { condominioStore, dispositivosStore, equipamentoStore, guaritaStore } = useContext(StoreContext);
  const { sincronizarTagsCondominio, sincronizarBiometriasCondominio, sincronizarFaciaisCondominio, sincronizarControlesCondominio, sincronizando, openRelatorio, setOpenRelatorio, sincronizarCartaoDoGuaritaParaControlIdCondominio, sincronizarControlIdOrigemDestino, sincronizarGuaritaHikvision } = useDispositivo();
  const [routes, setRoutes] = useState('dados');
  const [equipamentoOrigem, setEquipamentoOrigem] = useState('');
  const [equipamentoDestino, setEquipamentoDestino] = useState('');
  const [guaritaOrigem, setGuaritaOrigem] = useState('');
  const [hikvisionDestino, setHikvisionDestino] = useState('');
  const [modalConfirmarcao, setModalConfirmacao] = useState({
    tag: false,
    digital: false,
    controle: false,
    facial: false,
    guaritaControlId: false,
    origemDestino: false
  });

  useEffect(() => {
    if (condominioStore.selecionado) {
      equipamentoStore.listarEquipamentosBiometriaPorCondominio(condominioStore.selecionado.id);
    }
  }, [condominioStore.selecionado]);


  const ComponentDados = () => {
    return (
      <ContainerComponent className=''>
        <div className='d-flex box'>
          <p className='title'>
            <BsTags className='icon' /> <b>Sincronização de tags do condomínio.</b> <br />
            Clique no botão ao lado para sincronizar todas as tags dos condomínios.<br />
            (Esse processo pode demorar alguns minutos)
          </p>

          <div className='align-self-center card-button'>
            <Button appearance='primary' color={'violet'} onClick={() => setModalConfirmacao({ ...modalConfirmarcao, tag: true })}>Sincronizar tags do condomínio</Button>
          </div>
        </div>

        <div className='d-flex box'>
          <p className='title'>
            <RiRemoteControlLine size={22} className='icon' /> <b>Sincronização de controles do condomínio.</b> <br />
            Clique no botão ao lado para sincronizar todos os controles dos condomínios.<br />
            (Esse processo pode demorar alguns minutos)
          </p>

          <div className='align-self-center card-button'>
            <Button appearance='primary' color={'violet'} onClick={() => setModalConfirmacao({ ...modalConfirmarcao, controle: true })}> Sincronizar controles do condomínio</Button>
          </div>
        </div>

        <div className='d-flex box'>
          <p className='title'>
            <BiFace size={22} className='icon' /> <b>Sincronização de facial do condomínio.</b> <br />
            Clique no botão ao lado para sincronizar todos as faciais dos condomínios.<br />
            (Esse processo pode demorar alguns minutos)
          </p>

          <div className='align-self-center card-button'>
            <Button appearance='primary' color={'violet'} disabled={equipamentoStore.equipamentosBiometria.find((eq) => eq.tipoNome === 'HikvisonFacial') ? false : true || equipamentoStore.equipamentosBiometria.find((eq) => eq.tipoNome === 'IntelbrasFacial') ? false : true} onClick={() => setModalConfirmacao({ ...modalConfirmarcao, facial: true })}>Sincronizar as faciais do condomínio</Button>
          </div>
        </div>

        <div className='d-flex box'>
          <p className='title'>
            <MdFingerprint size={22} className='icon' /> <b>Sincronização de digital do condomínio.</b> <br />
            Clique no botão ao lado para sincronizar todos as digitais dos condomínios.<br />
            (Esse processo pode demorar alguns minutos)
          </p>

          <div className='align-self-center card-button'>
            <Button appearance='primary' color={'violet'} onClick={() => setModalConfirmacao({ ...modalConfirmarcao, digital: true })}>Sincronizar as digitais do condomínio</Button>
          </div>
        </div>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={sincronizando}
        >
          <Loader style={{ marginBottom: 50 }} size={'md'} content={'Sincronizando...'} />
        </Backdrop>
      </ContainerComponent>
    )
  }

  const ComponentTagGuarita = () => {
    return (
      <ContainerComponent>
        <p className='title-item'>Nice</p>
        <div className='container d-flex justify-content-center'>
          <div className='w-75'>
            <p className='title-export'>
              <HiOutlineDesktopComputer size={22} className='icon' /> <b>Guarita De Origem</b> <br /> <br />
              Selecione o módulo guarita de origem que será enviado as infromações.<br />
            </p>
            <SelectPicker
              block
              placeholder={'Selecione o guarita'}
              onOpen={() => {
                if (condominioStore.selecionado) {
                  guaritaStore.listar();
                }
              }}
              data={guaritaStore.guaritas.map((e) => ({ label: e.nome, value: e.id }))}
              renderMenu={renderMenuGuarita}
              onChange={(e) => {
                if (e) setGuaritaOrigem(e);
              }}
            />
          </div>

          <div className='d-flex justify-content-center' style={{ width: 200 }}>
            <FiArrowRight size={35} className='align-self-center' />
          </div>

          <div className='w-75'>
            <p className='title-export'>
              <HiOutlineDesktopComputer size={22} className='icon' /> <b>Equipamento de destino - Hikvision</b> <br /><br />
              Selecione o equipamento de destino que vai receber as infromações do outro equipamento.<br />
            </p>
            <SelectPicker
              block
              placeholder={'Selecione o equipamento de destino'}
              onOpen={() => {
                if (condominioStore.selecionado) {
                  equipamentoStore.listarEquipamentosBiometriaPorCondominio(condominioStore.selecionado.id);
                }
              }}
              data={equipamentoStore.equipamentosBiometria.filter(x => x.tipoNome.toLocaleLowerCase().includes("hikv")).map((e) => ({ label: e.nome, value: e.equipamentoId }))}
              renderMenu={renderMenu}
              disabledItemValues={[guaritaOrigem]}
              disabled={!guaritaOrigem}
              onChange={(e) => {
                if (e) setHikvisionDestino(e);
              }}
            />
          </div>
        </div>


        <div className='d-flex justify-content-center mt-5 p-3'>
          <Button disabled={!guaritaOrigem  || !hikvisionDestino} onClick={() => setModalConfirmacao({ ...modalConfirmarcao, origemDestino: true })}>Sincronizar equipamentos</Button>
        </div>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={sincronizando}
        >
          <Loader style={{ marginBottom: 50 }} size={'md'} content={'Sincronizando...'} />
        </Backdrop>
      </ContainerComponent>
    )
  }

  const renderMenu = (items: any) => {
    if (equipamentoStore.loading && equipamentoStore.equipamentosBiometria.length === 0) {
      return (
        <div className='d-flex justify-content-center p-4'>
          <Loader content={'Carregando equipamentos...'} />
        </div>
      );
    }

    if (equipamentoStore.equipamentosBiometria.length === 0) {
      return <NotFound message='Nenhum equipamento cadastrado nesse condomínio' />
    }
    return items;
  };

  const renderMenuGuarita = (items: any) => {
    if (guaritaStore.loading && guaritaStore.guaritas.length === 0) {
      return (
        <div className='d-flex justify-content-center p-4'>
          <Loader content={'Carregando guaritas...'} />
        </div>
      );
    }

    if (guaritaStore.guaritas.length === 0) {
      return <NotFound message='Nenhum guarita cadastrado nesse condomínio' />
    }
    return items;
  };

  const ComponentControlIds = () => {
    return (
      <ContainerComponent>
        <p className='title-item'>ControlId</p>
        <div className='container d-flex justify-content-center'>
          <div className='w-75'>
            <p className='title-export'>
              <HiOutlineDesktopComputer size={22} className='icon' /> <b>Equipamento Origem</b> <br /> <br />
              Selecione o equipamento de origem que será enviado as infromações.<br /><br />
              (Esse processo pode demorar alguns minutos)
            </p>
            <SelectPicker
              block
              placeholder={'Selecione o equipamento de origem'}
              onOpen={() => {
                if (condominioStore.selecionado) {
                  equipamentoStore.listarEquipamentosBiometriaPorCondominio(condominioStore.selecionado.id);
                }
              }}
              data={equipamentoStore.equipamentosBiometria.filter(x => x.tipoNome.toLocaleLowerCase().includes("control")).map((e) => ({ label: e.nome, value: e.equipamentoId }))}
              renderMenu={renderMenu}
              onChange={(e) => {
                if (e) setEquipamentoOrigem(e);
              }}
            />
          </div>

          <div className='d-flex justify-content-center' style={{ width: 200 }}>
            <FiArrowRight size={35} className='align-self-center' />
          </div>

          <div className='w-75'>
            <p className='title-export'>
              <HiOutlineDesktopComputer size={22} className='icon' /> <b>Equipamento de destino</b> <br /><br />
              Selecione o equipamento de destino que vai receber as infromações do outro equipamento.<br /><br />
              (Esse processo pode demorar alguns minutos)
            </p>
            <SelectPicker
              block
              placeholder={'Selecione o equipamento de destino'}
              onOpen={() => {
                if (condominioStore.selecionado) {
                  equipamentoStore.listarEquipamentosBiometriaPorCondominio(condominioStore.selecionado.id);
                }
              }}
              data={equipamentoStore.equipamentosBiometria.filter(x => x.tipoNome.toLocaleLowerCase().includes("control")).map((e) => ({ label: e.nome, value: e.equipamentoId }))}
              renderMenu={renderMenu}
              disabledItemValues={[equipamentoOrigem]}
              disabled={!equipamentoOrigem}
              onChange={(e) => {
                if (e) setEquipamentoDestino(e);
              }}
            />
          </div>
        </div>


        <div className='d-flex justify-content-center mt-5 p-3'>
          <Button disabled={!equipamentoOrigem || !equipamentoDestino} onClick={() => setModalConfirmacao({ ...modalConfirmarcao, origemDestino: true })}>Sincronizar equipamentos</Button>
        </div>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={sincronizando}
        >
          <Loader style={{ marginBottom: 50 }} size={'md'} content={'Sincronizando...'} />
        </Backdrop>
      </ContainerComponent>
    )
  }

  const renderRoutes = () => {
    switch (routes) {
      case 'dados':
        return ComponentDados();

      case 'tagGuarita':
        return ComponentTagGuarita();

      case 'equipamentos':
        return ComponentControlIds();
    }
  }

  return (
    <Container>
      <p className='title'>Campo de sincronização</p>

      <Nav appearance="subtle" activeKey={routes} onSelect={setRoutes}>
        <Nav.Item eventKey='dados' icon={<HiOutlineDatabase size={22} />}>
          Sincronização de dados
        </Nav.Item>
        <Nav.Item icon={<TbDatabaseExport size={22} />} eventKey='equipamentos'>Exportação de equipamentos</Nav.Item>
        <Nav.Item icon={<TbFileDatabase size={22} />} eventKey='tagGuarita'>Exportação guaritas</Nav.Item>
      </Nav>

      {renderRoutes()}

      <ModalConfirmacao show={modalConfirmarcao.tag} hide={() => setModalConfirmacao({ ...modalConfirmarcao, tag: false })} content={'Você deseja sincronizar todas as tags do condomínio?'} action={() => {
        if (condominioStore.selecionado) {
          sincronizarTagsCondominio(condominioStore.selecionado.id);
        }
      }} loading={sincronizando} />

      <ModalConfirmacao show={modalConfirmarcao.controle} hide={() => setModalConfirmacao({ ...modalConfirmarcao, controle: false })} content={'Você deseja sincronizar todos os controles do condomínio?'} action={() => {
        if (condominioStore.selecionado) {
          sincronizarControlesCondominio(condominioStore.selecionado.id);
        }
      }} loading={sincronizando} />

      <ModalConfirmacao show={modalConfirmarcao.facial} hide={() => setModalConfirmacao({ ...modalConfirmarcao, facial: false })} content={'Você deseja sincronizar todas as faciais do condomínio?'} action={() => {
        if (condominioStore.selecionado) {
          sincronizarFaciaisCondominio({
            condominioId: condominioStore.selecionado.id
          });
        }
      }} loading={sincronizando} />

      <ModalConfirmacao show={modalConfirmarcao.digital} hide={() => setModalConfirmacao({ ...modalConfirmarcao, digital: false })} content={'Você deseja sincronizar todas as digitais do condomínio?'} action={() => {
        if (condominioStore.selecionado) {
          sincronizarBiometriasCondominio(condominioStore.selecionado.id);
        }
      }} loading={sincronizando} />

      <ModalConfirmacao show={modalConfirmarcao.guaritaControlId} hide={() => setModalConfirmacao({ ...modalConfirmarcao, guaritaControlId: false })} content={'Você deseja sincronizar as tags do guarita para esse controlId?'} action={() => {
        if (condominioStore.selecionado) {
          if (equipamentoDestino) {
            sincronizarCartaoDoGuaritaParaControlIdCondominio(equipamentoDestino);
          }
          else {
            alert('Erro ao sincronizar tags do guarita para o controlId')
          }
        }
      }} loading={sincronizando} />


      <ModalConfirmacao show={modalConfirmarcao.origemDestino} hide={() => setModalConfirmacao({ ...modalConfirmarcao, origemDestino: false })} content={'Você deseja sincronizar as tags biometrias do equipamento de origem para o de destino?'} action={() => {
        if (condominioStore.selecionado) {
          if (equipamentoOrigem && equipamentoDestino && routes === "equipamentos") {
            sincronizarControlIdOrigemDestino({
              controlIdOrigem: equipamentoOrigem,
              controlIdDestino: equipamentoDestino
            });
          }
          else if (guaritaOrigem && hikvisionDestino && routes === "tagGuarita") {
            sincronizarGuaritaHikvision(guaritaOrigem, hikvisionDestino);
          }
          else {
            alert('Erro ao sincronizar')
          }
        }
      }} loading={sincronizando} />



      <Drawer backdrop open={openRelatorio} onClose={() => setOpenRelatorio(false)}>
        <Drawer.Header>
          <Drawer.Title>RELATÓRIO DA SINCRONIZAÇÃO</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setOpenRelatorio(false)}>Fechar</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          {
            dispositivosStore.relatorioSincronizacao?.map((relatorio, i) =>
            (
              <div key={i} className={'mt-2'}>
                <p className='mt-1' style={{ fontSize: 17, fontWeight: 550 }}>MORADOR: {relatorio.nome?.toUpperCase()}</p>
                <p className='mt-1' style={{ fontSize: 13, fontWeight: 550 }}>Sincronização realizado com sucesso</p>
                <div className='p-2'>
                  {
                    relatorio.acertos.length !== 0
                      ?
                      <>
                        {
                          relatorio.acertos.map((acerto, i) =>
                          (
                            <span key={i}>
                              <Timeline className="custom-timeline">
                                <Timeline.Item dot={<AiOutlineCheck size={20} />}>
                                  <p className='m-0' style={{ fontSize: 12, fontWeight: 850 }}>{acerto.local}</p>
                                  <p className='m-0' style={{ color: 'green' }}>{acerto.notificacao}</p>
                                </Timeline.Item>
                              </Timeline>
                            </span>
                          ))
                        }
                      </>
                      :
                      'SEM ERRO'
                  }
                </div>
                <p className='mt-1' style={{ fontSize: 15, fontWeight: 550 }}>Sincronização com erros</p>
                <div className='p-2'>
                  {
                    relatorio.erros.length !== 0
                      ?
                      <span key={i}>
                        {
                          relatorio.erros.map((erro, i) =>
                          (
                            <>
                              <Timeline className="custom-timeline">
                                <Timeline.Item dot={<BiErrorCircle size={20} />}>
                                  <p className='m-0' style={{ fontSize: 12, fontWeight: 850 }}>{erro.local ? erro.local : 'sem local'}</p>
                                  <p className='m-0' style={{ color: 'red' }}>{erro.notificacao}</p>
                                </Timeline.Item>
                              </Timeline>
                            </>
                          ))
                        }
                      </span>
                      :
                      'SEM ERRO'
                  }
                </div>
              </div>
            ))
          }
          {/* erros
            {
                dispositivosStore.relatorio?.erros.map((acerto) =>
                <p>{acerto.local}</p>)
            } */}
        </Drawer.Body>
      </Drawer>

    </Container>
  )
}

export default observer(SincronizacaoCard);
